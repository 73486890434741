body, html {
  height: 100%;
}

.full-height {
  height: 100vh;
}

.v-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.no-user-select {
  user-select: none;
}
.handle-cursor {
  cursor: pointer;
}