.room {
  font-size: 12px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  .col1 {
    flex-grow: 1;
    min-height: 100vh;
    background: url('../assets/desktop16x9.png');
    background-repeat: no-repeat;
    background-position: center;
    div {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
  .col2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 120px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    .row1 {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 180px;
      video {
        width: 120px;
        height: 90px;
      }
    }
    .row2 {
      height: 38px;
      .form-control {
        margin: 0;
      }
    }
    .row3 {
      max-height: calc(100vh - 180px - 38px);
      overflow-y: auto;
      .list-group-item {
        padding: 4px;
        max-width: 120px;
        overflow-x: hidden;
      }
    }
  }
}
.screen-placeholder {
  background: url('../assets/desktop16x9.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.camera-placeholder {
  background: url('../assets/default-poster.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 120px;
  height: 90px;
}

.form-control {
  font-size: 12px !important;
}
