$width: 120px;
$height: 90px;

box4x3 {
  width: $width;
  height: $height;
}

box16x9 {
  width: 768px;
  height: 432px;
}

.subscription {
  background-color: #d8d8d8;
  @extend box4x3;
  video {
    @extend box4x3;
    background-color: #d8d8d8;
    background-position: center center;
    background-size: contain;
  }
  .hidden {
    display: none;
  }
  .overlay {
    @extend box4x3;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    position: absolute;
  }
}

.camera-subscription {
  video {
    background: url('../assets/default-poster.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .overlay {
    .unmute {
      width: 120px !important;
      height: 90px !important;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 0;
      color: #333;
      position: absolute;
      z-index: 1001;
      border: none;
    }
  }
  .controls-panel {
    @extend box4x3;
    z-index: 2;
    position: absolute;
    justify-content: space-between;
    flex-direction: column;
    display: flex;

    .name-display {
      background-color: rgba(255, 255, 255, 0.5);
      color: #000;
      padding: 2px;
      width: $width;
      text-align: center;
      display: none;
    }
    .volume-control {
      display: none;
    }
  }
  .controls-panel:hover {
    .volume-control,
    .name-display {
      display: block;
    }
  }
}

.screen-subscription {
  @extend box16x9;
  video {
    @extend box16x9;
    background: url('../assets/desktop16x9.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.camera-control-subscription {
  video {
    background: url('../assets/default-poster.png');
    background-position: center;
    background-size: cover;
  }
  .controls-panel {
    @extend box4x3;
    z-index: 2;
    position: absolute;
    justify-content: flex-end;
    flex-direction: column;
    display: flex;
    .buttons {
      background-color: rgba(255, 255, 255, 0.5);
      padding: 6px;
      width: $width;
      text-align: center;
      visibility: hidden;
      button {
        width: 48px;
        background-color: transparent;
        border: none;
        outline: none;
      }
    }
    .icon {
      width: 24px;
      height: 24px;
    }
  }
  .controls-panel:hover {
    .buttons {
      visibility: visible;
    }
  }
}
